import React, { ReactNode } from 'react'

import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'

import { CustomButtonProps } from '../Button'
import Button from '../Button/Button'

export interface MenuItems {
  label: string
  onClick: () => void
  dataAction?: string
}
interface Props {
  menuItems: MenuItems[]
  buttonProps?: CustomButtonProps
  buttonDataTestId?: string
  children?: ReactNode
}

const Dropdown: React.FC<Props> = ({
  menuItems,
  buttonProps,
  children,
  buttonDataTestId,
}) => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)

  return (
    <>
      <Button
        onClick={handleClick}
        {...buttonProps}
        buttonDataTestId={buttonDataTestId}
      >
        {children}
      </Button>
      <Menu
        id="cardContextMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transitionDuration={0}
        elevation={3}
        sx={{
          marginTop: '6px',
          padding: 0,
          borderRadius: '10px',
          '& ul': {
            padding: 0,
            '& li': {
              paddingTop: '10px',
              paddingBottom: '10px',
            },
          },
        }}
      >
        {menuItems.map((item) => (
          <MenuItem
            key={item.label}
            onClick={() => {
              item.onClick()
              handleClose()
            }}
            disableRipple
            data-action={item.dataAction ? item.dataAction : undefined}
            data-testid={`drop-down-item-${item.label.replaceAll(' ', '-')}`}
          >
            {item.label}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default Dropdown
